"use client";

import React, { useEffect } from "react";
import { sendError } from "../components/ErrorBoundary/helpers";

type Props = {
    error: Error;
};

const GlobalError: React.FC<Props> = ({ error }: Props) => {
    useEffect(() => {
        sendError(error);
    }, [error]);

    return (
        <html lang="de">
            <body>
                <div>global-error occured</div>
            </body>
        </html>
    );
};

export default GlobalError;
